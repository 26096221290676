var parseURL = function (url) {
	var parser = document.createElement('a'),
					searchObject = {},
					queries, split, i;
	// Let the browser do the work
	parser.href = url;
	// Convert query string to object
	queries = parser.search.replace(/^\?/, '').split('&');
	for (i = 0; i < queries.length; i++) {
		split = queries[i].split('=');
		if (split) {
			split[0] = decodeURIComponent(split[0]);
			split[1] = decodeURIComponent(split[1]);
			if (split[0].indexOf('[]') !== -1) {
				//array
				if (!searchObject[split[0]]) {
					searchObject[split[0]] = [];
				}
				searchObject[split[0]].push(split[1]);
			} else {
				searchObject[split[0]] = split[1];
			}
		}
	}
	return {
		protocol: parser.protocol,
		host: parser.host,
		hostname: parser.hostname,
		port: parser.port,
		pathname: parser.pathname,
		search: parser.search,
		searchObject: searchObject,
		hash: parser.hash,
		parser: parser,
		toString: function () {
			var search = (this.search) ? '?' + this.search : '';
			return this.protocol + '//' + this.host + this.pathname + search + this.hash;
		},
		buildQuery: function () {
			var searches = [];
			for (var i in this.searchObject) {
				searches.push(encodeURIComponent(i) + '=' + encodeURIComponent(this.searchObject[i]));
			}
			this.search = searches.join('&');
			return this;
		}
	}
};
window.parseURL = parseURL;
$(function(){
	document.querySelectorAll('img.youtube').forEach(function(img){
		//parse youtube url
		if(img.alt){
			var url  = parseURL(img.alt),
				youtube_id='';
			if(url.search){
				youtube_id = url.searchObject['v']
			}else if(url.pathname){
				youtube_id = url.pathname.split('/').pop()
			}
			var iframe = document.createElement('iframe');
			iframe.width = img.width;
			iframe.height = img.height;
			iframe.setAttribute('src', 'https://www.youtube.com/embed/'+youtube_id);
			iframe.setAttribute('frameborder', 0);
			iframe.setAttribute('allowfullscreen', '');
			iframe.setAttribute('style', img.getAttribute('style'));
			img.parentNode.replaceChild(iframe, img)
		}
	});
	document.querySelectorAll('img.googlemap').forEach(function(img){
		//parse googlemap iframe
		if(img.alt){
			var url  = img.alt,
				iframe = document.createElement('iframe');
			iframe.width = img.width;
			iframe.height = img.height;
			iframe.setAttribute('src', url);
			iframe.setAttribute('frameborder', 0);
			iframe.setAttribute('allowfullscreen', '');
			iframe.setAttribute('style', img.getAttribute('style'));
			img.parentNode.replaceChild(iframe, img)
		}
	})
	var max_height = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		),
		body_height = $('#body').outerHeight(),
		footer = $('.footer'),
		footer_height = footer.outerHeight(),
		footer_pos = footer.position();

	if((body_height+footer_height) >= (footer_pos.top+footer_height)){
		$('.footer').css('bottom', 'auto');
	}

	$('a.page-scroll').click(function() {
		if($(this).hasClass('dropdown-item')){
			$(this).parents('.dropdown-menu.show,.dropdown.menu').removeClass('show');
		}
		if(location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname){
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if(target.length){
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 700);
				return false;
			}
		}
	});
	$('a.force-link').click(function(){
		location.href = this.href;
	});

	baguetteBox.run('.c-image', {noScrollbars: true});
	baguetteBox.run('.c-gallery');

	$(window).scroll(function(){
		if($(this).scrollTop() > 200){
			$('#btt').fadeIn(1000);
		}else{
			$('#btt').fadeOut(500);
		}
	});

	$('#btt').on('click', function(){
		$('html, body').animate({scrollTop: 0}, 700);
		this.blur();
		return false;
	});
});